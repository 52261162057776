body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #6d6e71 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  color: #6d6e71;
}

.ui.header {
  color: #354049;
}

.cg .ui.button.primary, .cg .ui.button.primary:active, .cg .ui.button.primary:focus {
  background-color: #b52c74;
}

.cg .ui.button.primary:hover {
  background-color: #a22768;
}

.cg .ui.menu a.item {
  color: #6d6e71;
}

/*.cg .ui.menu a.item:not(:hover) {*/
/*  color: #6d6e71 !important;*/
/*}*/

.cg .ui.menu .item.active {
  background-color: white;
}

.cg .ui.menu a.item:hover {
  background-color: white;
  color: #354049;
}

.cg .ui.menu a.item .underline {
  display: none;
}

.cg .ui.menu a.item:hover .underline, .cg .ui.menu a.item.active .underline {
  display: block;
}

.ui.inverted.modals .ui.modal > .close {
  color: #354049;
}

/*.cg .ui.menu a.item .link:hover:after {*/
/*  content: '';*/
/*  height: 3px;*/
/*  position: absolute;*/
/*  bottom: -10px;*/
/*  width: 100%;*/
/*  background-color: #6d6e71;*/
/*}*/
/*.cg .ui.menu a.item:hover:after {*/
/*  content: '';*/
/*  border-bottom: 3px solid #000;*/
/*  position:absolute;*/
/*  top: 60px;*/
/*  width: 100%;*/
/*  !*top: 5px;*!*/
/*  !*right:0;*!*/
/*  !*bottom:0;*!*/

/*  !*border-top: 10px solid transparent;*!*/
/*  !*border-bottom: 10px solid transparent;*!*/
/*  !*border-left: 10px solid #303030;*!*/
/*  !*content: "";*!*/
/*}*/

#root .awssld {
  width: 100%;
  height: 100%;
  --loader-bar-color: #f1f1f1;
  --loader-bar-height: 0;
}

#root .awssld .awssld__wrapper {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#root .awssld__content > img {
  object-fit: contain;
  background-color: white;
}

.awssld .awssld__box {
}

#root .ui.menu {
  border: 0;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}

#root .ui.menu .item::before {
  width: 0;
}

.cg.nav {
  background-color: white;
}

.cg.nav-break, .cg.nav.mobile {
  /*border-bottom: 1px solid #2d75b5;*/
  border-bottom: 1px solid #6d6e71;
}

#root .ui.menu > .item.logo {
  padding-left: 0;
  /*margin-top: 1em;*/
  /*margin-bottom: 1em;*/
}

p.subtext {
  font-size: 1.4em;
  font-weight: 400;
  color: #6d6e71;
}

p.subtext a {
  color: #6d6e71;
  text-decoration: none;
}

p.subtext a:hover {
  color: #6d6e71;
  text-decoration: underline;
}

.cg .info-container {
  display: flex;
  height: 100%;
  align-items: center;
}

.cg.welcome-banner {
  background-color: white;
  padding: 3em 0;
}

.cg.details-banner {
  height: 500px;
  font-size: 1.4em;
  display: flex;
  align-items: center;
  background-position: center;
}

.cg.details-banner p {
  color: white;
}

.cg.details-banner h2 {
  color: #77d2f7;
}

.cg.details-banner a {
  color: white;
}

.cg.details-banner a:hover {
  text-decoration: underline;
}

.cg.mystery-banner {
  background-color: white;
  padding: 3em 0;
}

.cg.check {
  background-color: white;
  padding: 3em 0;
}

.cg.check img.verify {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cg.footer {
  color: white;
  background-color: #6d6e71;
  padding: 5em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cg.footer a {
  color: white;
}

.cg.footer a:hover {
  text-decoration: underline;
}

.cg.footer .row + .row {
  margin-top: 1em;
}

.cg.footer img.logo {
  max-width: 100%;
  padding: 0 1em;
  max-height: 70px;
}

.cg.thumbnail {
  display: flex;
  justify-content: center;
}

.cg.thumbnail > div {
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 500px;
}

.cg.thumbnail p {
  background-color: #f1f1f1;
  padding: 1em;
}

.cg.thumbnail img {
  width: 100%;
  display: block;
}

.aspect-container {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}

.aspect-container > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.cg.gallery {
  padding: 3em 0;
  background-color: white;
}

.cg.colors {
  padding: 3em 0;
  background-color: white;
}

.cg.colors .color {
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 6px;
  padding: 10px;
}

.cg.colors .color + .color {
  margin-top: .5em;
}

.cg.item {
  padding: 3em 0;
  background-color: white;
}

.cg.page {
  padding: 3em 0;
  background-color: white;
}

.cg.solve {
  padding: 3em 0;
  background-color: white;
}

.ril__toolbar, .ril__caption {
  background-color: unset !important;
}

.cg.clock-item {
  display: inline-block;
  border: 1px solid #253c93;
  border-radius: 6px;
  padding: .5em;
  text-align: center;
  width: 55px;
}

.cg.clock-item + .cg.clock-item {
  margin-left: .5em;
}

.cg.clock-item .value {
  font-size: 1.4em;
  font-weight: bold;
  color: #253c93;
}

.cg.clock-item .label {
  text-transform: uppercase;
  color: #6d6e71;
}